import _objectWithoutProperties from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["primaryCategory"];
import React from "react";
var __jsx = React.createElement;
import Link from 'next/link';
import EventTrackingHelper from "../../utils/trackingUtilities";
import InfoIcon from "../_global/Icons/InfoIcon";
import ReactTooltip from 'react-tooltip';
import { colours } from "../../constants/css";
import OffersCarousel from "../OffersCarousel";
var viewAllIcon = "/static/icons/icon_arrow_right.svg";
import { CategorySection, CategoryBannerText, CategorySectionTitle, ViewCategory, CategoryHeaderWrapper } from "./style";
var ONLINE_MALL = 'ONLINE_MALL';
export default function CategoryBrandsCarousel(_ref) {
  var sectionTitle = _ref.sectionTitle,
    showIcon = _ref.showIcon,
    merchants = _ref.merchants,
    categories = _ref.categories,
    bonusCampaignItems = _ref.bonusCampaignItems;
  var brandsArr = function brandsArr(array) {
    array = array.filter(function (merchant) {
      return merchant.isVisible;
    });
    return array.reduce(function (r, _ref2) {
      var primaryCategory = _ref2.primaryCategory,
        object = _objectWithoutProperties(_ref2, _excluded);
      var temp = r.find(function (o) {
        return getParentCategory(o.primaryCategory).title === getParentCategory(primaryCategory).title;
      });
      if (!temp) {
        temp = {
          displayOrder: getParentCategory(primaryCategory).displayOrder,
          primaryCategory: getParentCategory(primaryCategory).title,
          children: []
        };
        r.push(temp);
      }
      temp.children.push(object);
      return r.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
      });
    }, []);
  };
  var getParentCategory = function getParentCategory(categoryName) {
    var selectedObj = {
      title: categoryName,
      url: '/categories/',
      displayOrder: 0
    };
    categories && categories.forEach(function (item) {
      if (item.displayName === categoryName) {
        selectedObj.title = item.displayName;
        selectedObj.url = "/category/".concat(item.categoryId);
        selectedObj.displayOrder = item.displayOrder;
      }
      item.subCategories && item.subCategories.forEach(function (subCat) {
        if (subCat.displayName === categoryName) {
          selectedObj.title = item.displayName;
          selectedObj.url = "/category/".concat(item.categoryId);
          selectedObj.displayOrder = item.displayOrder;
        }
      });
    });
    return selectedObj;
  };
  return __jsx(CategorySection, null, __jsx(CategoryHeaderWrapper, null, __jsx(CategoryBannerText, null, sectionTitle, showIcon ? __jsx(React.Fragment, null, __jsx(InfoIcon, {
    "data-tip": "",
    "data-for": ONLINE_MALL
  }), __jsx(ReactTooltip, {
    event: "click",
    className: "howItWorksToolTip",
    clickable: true,
    offset: {
      right: 15
    },
    id: ONLINE_MALL,
    afterShow: function afterShow() {
      EventTrackingHelper.trackGenericAction('online mall|Icon');
    },
    backgroundColor: colours.qantasTeal
  }, __jsx("a", {
    href: "/how-it-works",
    onClick: function onClick() {
      EventTrackingHelper.trackGenericAction('online mall|How it works');
    },
    role: "link",
    className: "mobileToolTipLink"
  }, "How it works"))) : ''), __jsx(Link, {
    href: "/all-stores"
  }, __jsx("a", {
    onClick: function onClick() {
      EventTrackingHelper.trackGenericAction('online mall|View all stores');
    },
    role: "link"
  }, "View all brands"))), merchants && brandsArr(merchants).map(function (category, index) {
    return __jsx(React.Fragment, {
      key: index
    }, __jsx(CategorySectionTitle, null, category.primaryCategory), __jsx(ViewCategory, null, __jsx("a", {
      href: getParentCategory(category.primaryCategory).url
    }, __jsx("img", {
      alt: "View all",
      src: viewAllIcon
    }))), __jsx(OffersCarousel, {
      offers: category.children,
      categoryUrl: getParentCategory(category.primaryCategory).url,
      bonusCampaignItems: bonusCampaignItems,
      offerType: 'CATEGORIES',
      noMargin: true
    }));
  }));
}