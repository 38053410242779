var __jsx = React.createElement;
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { actualPointsCalculator } from "../Offer/offerUtils";
import { TopListItem, ListItemImgWrapper, ListItemBlock, ListItemHeading, ListItemSub, ShopLink } from "./style";
var ListItem = function ListItem(props) {
  var _actualPointsCalculat = actualPointsCalculator(props),
    actualPoints = _actualPointsCalculat.actualPoints;
  return __jsx(TopListItem, null, __jsx(Link, {
    href: "/merchant?slug=".concat(props.merchantId.replace(/\+/g, '%2B')),
    as: "/".concat(props.merchantId)
  }, __jsx("a", null, __jsx(ListItemImgWrapper, null, __jsx(Image, {
    src: props.src,
    alt: props.title,
    width: 60,
    height: 60,
    layout: "responsive",
    sizes: "10vw"
  })), __jsx(ListItemBlock, null, __jsx(ListItemHeading, null, props.title), props.bespoke ? __jsx(ListItemSub, null, props.bespoke) : __jsx(ListItemSub, null, "Earn ", actualPoints, " ", actualPoints > 1 ? 'points' : 'point', " per $", props.dollarSpend, ' ', "spent"), __jsx(ShopLink, null, "Shop now")))));
};
ListItem.defaultProps = {
  title: '',
  src: '',
  merchantId: '',
  dollarSpend: '',
  bespoke: ''
};
export default ListItem;